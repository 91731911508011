import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../../common/AppDivider';
import { serverInstanceNodeAddress } from '../CreateMaxScaleWizardFrom';

export type CreateMaxScalePreviewProps = {
    form: FormInstance;
};

export default CreateMaxScalePreview;

function CreateMaxScalePreview({ form }: CreateMaxScalePreviewProps) {
    const {
        maxScaleWhere,
        maxScaleConfiguration,
        maxScaleServerInstances,
    } = form.getFieldsValue(true);
    const [address] = maxScaleWhere.address;
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Where to install"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Server address" labelStrong>
                    {address?.extraData.hostname ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Threads" labelStrong>
                    {maxScaleWhere.threads ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="RR Port (Port for Round Robin Listener)"
                    labelStrong
                >
                    {maxScaleWhere.portRR ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="RW Port (Port for read/write split Listener)"
                    labelStrong
                >
                    {maxScaleWhere.portRW ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Disable firewall" labelStrong>
                    {`${maxScaleWhere.firewall ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Disable AppArmor/SELinux" labelStrong>
                    {`${maxScaleWhere.appArmor ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item
                    label="MaxScale admin username"
                    labelStrong
                >
                    {`${maxScaleConfiguration.maxScaleAdminUser ?? ''}`}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item
                    label="MaxScale MySQL username"
                    labelStrong
                >
                    {`${maxScaleConfiguration.maxScaleMySQLUser ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />

            <SpaceDescriptions
                direction="vertical"
                title="Server instances"
                size="small"
                alignItems="right"
            >
                {serverInstanceNodeAddress(maxScaleServerInstances).map(
                    (nodeAddress, index) => {
                        return (
                            <SpaceDescriptions.Item key={index}>
                                {`${nodeAddress ?? ''}`}
                            </SpaceDescriptions.Item>
                        );
                    }
                )}
            </SpaceDescriptions>
        </div>
    );
}
