import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../common/Layout/SpaceDescriptions';
import AppDivider from '../../../../common/AppDivider';

export type ImportGarbdPreviewProps = {
    form: FormInstance;
};

export default ImportGarbdPreview;

function ImportGarbdPreview({ form }: ImportGarbdPreviewProps) {
    const { garbdConfig } = form.getFieldsValue(true);
    const [address] = garbdConfig.address;
    return (
        <div>
            <SpaceDescriptions
                direction="vertical"
                title="Configuration"
                size="small"
                alignItems="right"
            >
                <SpaceDescriptions.Item label="Server address" labelStrong>
                    {address?.extraData.hostname ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="Port" labelStrong>
                    {garbdConfig.port ?? ''}
                </SpaceDescriptions.Item>
                <SpaceDescriptions.Item label="CmdLine" labelStrong>
                    {garbdConfig.cmdline ?? ''}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
        </div>
    );
}
