import { useState, useEffect } from 'react';
import { Input, Space, Row, Col, Radio, RadioChangeEvent } from 'antd';
import { Cron } from 'react-js-cron';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import './CronInput.less';
import 'react-js-cron/dist/styles.css';

const MODE_SIMPLE = 'simple';
const MODE_ADVANCED = 'advanced';

export type CronInputProps = {
    value?: string;
    onChange?: Function;
};

function CronInput({ value, onChange, ...rest }: CronInputProps) {
    const [mode, setMode] = useState(MODE_SIMPLE);
    const [cronValue, setCronValue] = useState(value);
    const [cronAdvancedValue, setCronAdvancedValue] = useState();

    const handleCronValueChange = (value) => {
        setCronValue(value);
        onChange?.(value);
    };

    const updateCronValue = (attribute, value) => {
        const advancedValue = {
            ...cronAdvancedValue,
            [attribute]: value.replace(/\s/g, ''),
        };
        const cronValue = Object.values(advancedValue).join(' ');
        setCronAdvancedValue(advancedValue);
        setCronValue(cronValue);
        onChange?.(cronValue);
    };

    function parseCron(cronValue) {
        if (!cronValue) {
            cronValue = '* * * * *';
        }
        const parts = cronValue.split(' ');
        if (parts.length !== 5) {
            throw new Error('Invalid cron value');
        }
        const [minute, hour, day, month, weekday] = parts;
        return {
            minute,
            hour,
            day,
            month,
            weekday,
        };
    }

    const handleModeChange = ({ target: { value } }: RadioChangeEvent) => {
        setMode(value);
    };

    useEffect(() => {
        const parseValue = parseCron(value);
        setCronAdvancedValue({
            minute: parseValue.minute,
            hour: parseValue.hour,
            day: parseValue.day,
            month: parseValue.month,
            weekday: parseValue.weekday,
        });
    }, [value]);
    return (
        <>
            <Space style={{ paddingBottom: '10px' }}>
                <Radio.Group
                    options={[
                        { label: 'Simple', value: MODE_SIMPLE },
                        { label: 'Advanced', value: MODE_ADVANCED },
                    ]}
                    onChange={handleModeChange}
                    optionType="button"
                    value={mode}
                />
            </Space>
            <Row>
                {mode === MODE_SIMPLE ? (
                    <Cron
                        value={cronValue}
                        setValue={handleCronValueChange}
                        className="cron-schedule"
                        {...rest}
                    />
                ) : (
                    <>
                        <Space>
                            <p>
                                Backup schedule using UNIX cron format. Learn
                                more ...{' '}
                                <InfoIcon
                                    info={
                                        'Set backup schedule using UNIX cron format: * * * * *. Each field represents: minute (0-59), hour (0-23), day of the month (1-31), month (1-12), day of the week (0-7, with 0 and 7 representing Sunday). Use * for any value. Examples: 0 0 * * * for daily at midnight, 0 2 * * 1 for Mondays at 2 AM.'
                                    }
                                />
                            </p>
                        </Space>
                        <Row justify="space-around" align="middle">
                            <Col span={4}>
                                <Space direction="vertical">
                                    <div>{'Minute '}</div>
                                    <Input
                                        value={cronAdvancedValue.minute}
                                        onChange={(e) =>
                                            updateCronValue(
                                                'minute',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Space>
                            </Col>
                            <Col span={4}>
                                <Space direction="vertical">
                                    <div>{'Hour '}</div>
                                    <Input
                                        value={cronAdvancedValue.hour}
                                        onChange={(e) =>
                                            updateCronValue(
                                                'hour',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Space>
                            </Col>
                            <Col span={4}>
                                <Space direction="vertical">
                                    <div>{'Day '}</div>
                                    <Input
                                        value={cronAdvancedValue.day}
                                        onChange={(e) =>
                                            updateCronValue(
                                                'day',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Space>
                            </Col>
                            <Col span={4}>
                                <Space direction="vertical">
                                    <div>{'Month '}</div>
                                    <Input
                                        value={cronAdvancedValue.month}
                                        onChange={(e) =>
                                            updateCronValue(
                                                'month',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Space>
                            </Col>
                            <Col span={4}>
                                <Space direction="vertical">
                                    <div>{'Weekday '}</div>
                                    <Input
                                        value={cronAdvancedValue.weekday}
                                        onChange={(e) =>
                                            updateCronValue(
                                                'weekday',
                                                e.target.value
                                            )
                                        }
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </>
                )}
            </Row>
        </>
    );
}
export default CronInput;
