import React from 'react';
import { Col, Row } from 'antd';
import { Form } from 'antd';

import CcCluster, {
    CcClusterType,
} from '../../../../services/models/CcCluster';

import { FormInstance } from 'antd/lib/form';
import FormItemInlineSwitch from '../../../../common/DataEntry/FormItemInlineSwitch';
import AppRadio from '../../../../common/DataEntry/AppRadio';
import KeepAlivedHostnameField from '../../FormFields/KeepAlivedHostnameField';
import { getTopologyValidator } from '../../loadBalancerHelpers';
import DisableFirewallField
    from "../../../../common/Form/Fields/DisableFirewallField";
import DisableSelinuxField
    from "../../../../common/Form/Fields/DisableSelinuxField";

type CreateKeepalivedConfigurationProps = {
    selectedCluster?: CcCluster; // @todo make cluster prop required
    form: FormInstance;
};

export default CreateKeepalivedConfiguration;

function CreateKeepalivedConfiguration({
    selectedCluster,
    form,
}: CreateKeepalivedConfigurationProps) {
    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Load balancer type</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['keepalivedConfiguration', 'type']}
                        label=""
                    >
                        <AppRadio.Group>
                            {selectedCluster &&
                                [
                                    CcClusterType.TYPE_REPLICATION,
                                    CcClusterType.TYPE_GALERA,
                                ].includes(selectedCluster?.clusterType) && (
                                    <AppRadio.Button value="proxysql">
                                        ProxySQL
                                    </AppRadio.Button>
                                )}
                            <AppRadio.Button value="haproxy">
                                HAProxy
                            </AppRadio.Button>
                            {selectedCluster &&
                                [
                                    CcClusterType.TYPE_REPLICATION,
                                    CcClusterType.TYPE_GALERA,
                                ].includes(selectedCluster?.clusterType) && (
                                    <AppRadio.Button value="maxscale">
                                        MaxScale
                                    </AppRadio.Button>
                                )}
                        </AppRadio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item shouldUpdate={true}>
                        {() =>
                            selectedCluster ? (
                                <KeepAlivedHostnameField
                                    name={[
                                        'keepalivedConfiguration',
                                        'hostname',
                                    ]}
                                    form={form}
                                    cluster={selectedCluster}
                                    nodeTypes={[
                                        form.getFieldValue([
                                            'keepalivedConfiguration',
                                            'type',
                                        ]),
                                    ]}
                                />
                            ) : null
                        }
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    {/* @todo move to separated component */}
                    <h3>Security configuration</h3>
                </Col>
                <Col span={12}>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <DisableFirewallField
                                name={['keepalivedConfiguration', 'firewall']}
                            />
                        </Col>
                    </Row>
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <DisableSelinuxField
                                name={['keepalivedConfiguration', 'appArmor']}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export function getCreateKeepalivedConfigurationValidation(form: FormInstance) {
    return [
        ['keepalivedConfiguration', 'hostname'],
        getTopologyValidator(form, ['keepalivedConfiguration', 'hostname']),
    ];
}
export function keepalivedConfigurationInitial(selectedCluster?: CcCluster) {
    return {
        keepalivedConfiguration: {
            type:
                selectedCluster?.clusterType === CcClusterType.TYPE_POSTGRESQL
                    ? 'haproxy'
                    : 'proxysql',
            firewall: true,
            appArmor: true,
        },
    };
}
