import React, { useState } from 'react';
import { Button, Col, Form, Row, Select, Space } from 'antd';
import Alert from '@severalnines/bar-frontend-components/build/lib/Feedback/Alert';

import FormFooter from '../../../common/FormFooter';
import CcCluster from '../../../services/models/CcCluster';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';

import { CcNodeType } from '../../../services/models/CcNode';

import SpaceWide from '../../../common/SpaceWide';
import BackupSelect from '../../../common/DataEntry/BackupSelect';
import { CcBackupMethod } from '../../../services/models/CcBackup';
import ClusterNodeSelect from '../../../common/DataEntry/ClusterNodeSelect';
import { FormInstance } from 'antd/lib/form';
import AppDateFormat from '../../../common/AppDateFormat';

export default ProxySqlSynchronizeInstancesFrom;

export type ProxySqlSynchronizeInstancesFromProps = {
    cluster: CcCluster;
    onSuccess?: () => void;
    onCancel?: () => void;
    form?: FormInstance;
};
export type ProxySqlSynchronizeInstancesFromValues = {
    sourceInstance?: string;
    importFile?: any;
    targetInstance?: string;
    synchronizationMethod?: string;
};

function ProxySqlSynchronizeInstancesFrom({
    cluster,
    form: parentForm,
    onSuccess,
    onCancel,
}: ProxySqlSynchronizeInstancesFromProps) {
    const [localForm] = Form.useForm<ProxySqlSynchronizeInstancesFromValues>();
    const form = parentForm || localForm;

    const [synchronizeValue, setSynchronizeValue] = useState<string>(
        'synchronize'
    );

    const { loading, send } = useCreateJob({
        clusterId: cluster?.clusterId,
        title: 'Synchronizing ProxySql Configuration',
        command: CmonJobInstanceCommand.PROXYSQL.toLowerCase() as CmonJobInstanceCommand,
        onSuccess,
    });

    const handleSubmit = async (
        values: ProxySqlSynchronizeInstancesFromValues
    ) => {
        await send(
            values.synchronizationMethod === 'synchronize'
                ? {
                      action: values.synchronizationMethod,
                      source_address: values.sourceInstance,
                      server_address: values.targetInstance,
                      clusterId: cluster.clusterId,
                  }
                : {
                      action: 'restore_backup',
                      backup_datadir_before_restore: false,
                      backupid: values.importFile.metadata.id as number,
                      server_address: values.targetInstance,
                  },
            values.synchronizationMethod === 'synchronize'
                ? {
                      job: {
                          title: `Synchronizing ProxySql Configuration ${values.sourceInstance}`,
                      },
                  }
                : {
                      job: {
                          title: `Restore ProxySql Configuration.`,
                      },
                  }
        );
    };

    const onChangeSync = (value: string) => {
        setSynchronizeValue(value);
        form.setFieldsValue({
            sourceInstance: '',
            targetInstance: '',
        });
    };

    const instanceValidator = async (rule: any, value: string) => {
        if (value === form.getFieldValue('targetInstance')) {
            throw new Error(
                `Source instance address should be different than  target instance address.`
            );
        }

        return true;
    };
    const targetValidator = async (rule: any, value: string) => {
        if (value === form.getFieldValue('sourceInstance')) {
            throw new Error(
                `Target instance address should be different than source instance address.`
            );
        }

        return true;
    };
    return (
        <ModalDefaultForm
            title="Synchronize Instances"
            form={form}
            footer={[]}
            onCancel={onCancel}
            defaultVisible={true}
        >
            <Form
                className="ProxySqlSynchronizeInstancesFrom"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{ synchronizationMethod: 'synchronize' }}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Synchronization method"
                            name={'synchronizationMethod'}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please select synchronization method',
                                },
                            ]}
                        >
                            <Select
                                onChange={onChangeSync}
                                options={[
                                    {
                                        label: 'Synchronize existing instances',
                                        value: 'synchronize',
                                    },
                                    {
                                        label: 'Import configuration file',
                                        value: 'import',
                                    },
                                ]}
                                value={synchronizeValue}
                                placeholder="select synchronization method"
                            />
                        </Form.Item>
                        {synchronizeValue === 'synchronize' ? (
                            <Form.Item
                                label="Source Instance"
                                name={'sourceInstance'}
                                rules={[
                                    {
                                        required: true,
                                        validator: instanceValidator,
                                    },
                                ]}
                            >
                                <ClusterNodeSelect
                                    cluster={cluster}
                                    primaryCandidatesOnly={false}
                                    databaseNodes={false}
                                    autoComplete={true}
                                    placeholder="select source instance"
                                    extractNodeValue={(node) => node.hostname}
                                    types={[CcNodeType.PROXYSQL]}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                label="Import configuration file"
                                name={'importFile'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select import configuration file',
                                    },
                                ]}
                            >
                                <BackupSelect
                                    backupLabel={''}
                                    clusterId={cluster.clusterId as number}
                                    filter={(backup) => {
                                        return backup.isMethod(
                                            CcBackupMethod.PROXYSQL
                                        );
                                    }}
                                    renderLabel={(backup) => (
                                        <Space size={5}>
                                            {`#${backup.getId()} (host: ${backup.getBackupHost()})`}
                                            -
                                            <AppDateFormat fromNow>
                                                {backup.getCreated()}
                                            </AppDateFormat>
                                        </Space>
                                    )}
                                />
                            </Form.Item>
                        )}
                        <SpaceWide direction="vertical">
                            <Form.Item
                                label="Target Instance"
                                name={'targetInstance'}
                                rules={[
                                    {
                                        required: true,

                                        validator: targetValidator,
                                    },
                                ]}
                            >
                                <ClusterNodeSelect
                                    cluster={cluster}
                                    primaryCandidatesOnly={false}
                                    autoComplete={true}
                                    databaseNodes={false}
                                    placeholder="select target instance"
                                    extractNodeValue={(node) => node.hostname}
                                    types={[CcNodeType.PROXYSQL]}
                                />
                            </Form.Item>
                        </SpaceWide>
                    </Col>
                    <Col span={12}>
                        <SpaceWide direction="vertical">
                            <Alert
                                type="warning"
                                message={
                                    <span>
                                        <h4>
                                            Configuration will be overwritten
                                        </h4>
                                        <span>
                                            Following configuration will be
                                            overwritten during the
                                            synchronization
                                        </span>
                                        <ol>
                                            <li>Query Rules</li>
                                            <li>Host Groups/Servers</li>
                                            <li>
                                                Users and corresponding MySQL
                                                users
                                            </li>
                                            <li>Global Variables</li>
                                            <li>Scheduler</li>
                                            <li>proxysql.cnf</li>
                                        </ol>
                                    </span>
                                }
                                showIcon={true}
                            />
                        </SpaceWide>
                    </Col>
                </Row>
                <FormFooter>
                    <Button
                        key="done"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        {synchronizeValue === 'synchronize'
                            ? 'Synchronize'
                            : 'import'}
                    </Button>
                </FormFooter>
            </Form>
        </ModalDefaultForm>
    );
}
