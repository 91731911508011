import React, { useMemo, useCallback } from 'react';
import { Col, Row, Space, InputNumber } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../../../../services/models/CcCluster';
import { getNodeHostWithDesc } from '../../../../Nodes/NodeFormat';
import FormItemInlineSwitch from '../../../../../common/DataEntry/FormItemInlineSwitch';
import { CcNodeType, CcNodeRole } from '../../../../../services/models/CcNode';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import SshCheckableNodesAutocomplete from '../../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTopologyValidator } from '../../../loadBalancerHelpers';

type ImportProxySQLConfigurationProps = {
    selectedCluster?: CcCluster;
    isImportConfig?: boolean;
    form: FormInstance;
};

export const proxySQLConfigurationInitialValues = () => {
    return {
        proxySQLConfiguration: {
            adminPort: 6032,
            listenPort: 6033,
            importConfig: false,
        },
    };
};

export default ImportProxySQLConfiguration;

function ImportProxySQLConfiguration({
    selectedCluster,
    isImportConfig,
    form,
}: ImportProxySQLConfigurationProps) {
    const getOptions = useCallback(
        (type?: CcNodeType): DefaultOptionType[] | undefined => {
            if (type) {
                const arrayOfNodes = selectedCluster?.getNodesByType(type);
                return arrayOfNodes?.map((node) => {
                    return {
                        value: `${node.hostname}`,
                        label: getNodeHostWithDesc(node),
                    };
                });
            } else {
                return selectedCluster
                    ?.getNodesByRoles([
                        CcNodeRole.CONTROLLER,
                        CcNodeRole.MASTER,
                        CcNodeRole.PRIMARY,
                        CcNodeRole.SLAVE,
                        CcNodeRole.SECONDARY,
                    ])
                    .map((node) => {
                        return {
                            value: `${node.hostname}`,
                            label: getNodeHostWithDesc(node),
                        };
                    });
            }
        },
        [selectedCluster]
    );

    // all options
    const optionsAddress: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(),
        [selectedCluster]
    );

    // SQLInstance options
    const optionsSQLInstance: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(CcNodeType.PROXYSQL),
        [selectedCluster]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>ProxySQL location</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={['proxySQLConfiguration', 'address']}
                        label={<Space>Server address</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select server address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={optionsAddress}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name={['proxySQLConfiguration', 'adminPort']}
                                label={<Space>Admin port</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter admin port number.',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="Click here to enter admin port."
                                ></InputNumber>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name={['proxySQLConfiguration', 'listenPort']}
                                label={<Space>Listening port</Space>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Enter port number.',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: '100%' }}
                                    placeholder="Click here to enter listening port."
                                ></InputNumber>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                {isImportConfig && (
                    <Col span={24}>
                        <Form.Item
                            name={['proxySQLConfiguration', 'proxySQLInstance']}
                            label={<Space>Source ProxySQL instance</Space>}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please select server ProxySQL Instance.',
                                },
                            ]}
                        >
                            <SshCheckableNodesAutocomplete
                                clusterId={selectedCluster?.clusterId}
                                emptyState={null}
                                singleNode={true}
                                formProps={{
                                    primaryTitle: null,
                                    primaryFormItemProps: {
                                        withLessMarginBottom: true,
                                    },
                                }}
                                autocompleteOptions={optionsSQLInstance}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>ProxySQL Configuration</h3>
                </Col>
                <Col span={12}>
                    <FormItemInlineSwitch
                        justify={true}
                        label={
                            <Space>
                                <span>Import configuration</span>
                                <InfoIcon
                                    info={
                                        'Import and apply the ProxySQL configuration from an existing instance or from a backup.'
                                    }
                                />
                            </Space>
                        }
                        name={['proxySQLConfiguration', 'importConfig']}
                        labelStrong={false}
                        valuePropName="checked"
                    />
                </Col>
            </Row>
        </div>
    );
}

export function getImportProxySQLConfigurationValidation(form: FormInstance) {
    return [
        ['proxySQLConfiguration', 'address'],
        ['proxySQLConfiguration', 'adminPort'],
        ['proxySQLConfiguration', 'listenPort'],
        ['proxySQLConfiguration', 'proxySQLInstance'],
        getTopologyValidator(form, ['proxySQLConfiguration', 'address']),
    ];
}
