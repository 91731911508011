import React, { useState } from 'react';
import { Checkbox, Form } from 'antd';
import TypographyText from '../TypographyText';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

export type FormCheckboxProps = FormItemProps & {
    name: string | string[];
    checked?: boolean;
    disabled?: boolean;
    onCheckChange?: (flag: boolean) => void;
    labelStrong?: boolean;
};

function FormCheckbox({
    name,
    checked,
    disabled,
    onCheckChange,
    labelStrong,
    label,
    ...rest
}: FormCheckboxProps) {
    const [value, setValue] = useState<boolean>(checked || false);

    const handleCheckedChange = (event: CheckboxChangeEvent) => {
        setValue(event.target.value);
        onCheckChange?.(event.target.value);
    };
    return (
        <Form.Item
            name={name}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
            {...rest}
        >
            <Checkbox checked={value} disabled={disabled} onChange={handleCheckedChange}>
                <TypographyText strong={labelStrong} muted={disabled}>{label}</TypographyText>
            </Checkbox>
        </Form.Item>
    );
}

export default FormCheckbox;
