import React, { useMemo, useCallback } from 'react';
import { Col, Row, Input, InputNumber } from 'antd';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CcCluster from '../../../../services/models/CcCluster';
import { getHostWithDesc } from '../../../Nodes/NodeFormat';
import { CcNodeType } from '../../../../services/models/CcNode';
import SshCheckableNodesAutocomplete from '../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTopologyValidator } from '../../loadBalancerHelpers';

type ImportGarbdConfigurationProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export const GARBD_CONFIG_INITIAL = {
    garbdConfig: {
        cmdline: '/usr/bin/garbd  --cfg /etc/garbd.cnf  --daemon',
        port: 4567,
    },
};

export default ImportGarbdConfiguration;

function ImportGarbdConfiguration({
    selectedCluster,
    form,
}: ImportGarbdConfigurationProps) {
    const getOptions = useCallback((): DefaultOptionType[] | undefined => {
        return selectedCluster
            ?.getHosts()
            .filter(
                (h) =>
                    !h.types.includes(CcNodeType.CONTROLLER) &&
                    !h.types.includes(CcNodeType.GALERA) &&
                    !h.types.includes(CcNodeType.MYSQL) &&
                    !h.types.includes(CcNodeType.POSTGRESQL) &&
                    !h.types.includes(CcNodeType.NDB)
            )
            .map((host) => {
                return {
                    value: `${host.hostname}`,
                    label: getHostWithDesc(host),
                };
            });
    }, [selectedCluster]);

    const options: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(),
        [selectedCluster]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={['garbdConfig', 'address']}
                        label={'Server address'}
                        rules={[
                            {
                                required: true,
                                message: 'Please select server address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={options}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['garbdConfig', 'cmdline']}
                        label={'CmdLine'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter CmdLine.',
                            },
                        ]}
                    >
                        <Input placeholder="Enter CmdLine."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['garbdConfig', 'port']}
                        label={'Port'}
                        rules={[
                            {
                                required: true,
                                message: 'Enter port number.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter port number."
                        ></InputNumber>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export function getImportGarbdConfigurationValidation(form: FormInstance) {
    return [
        // @todo make validation reusable with "Create" forms
        ['garbdConfig', 'address'],
        ['garbdConfig', 'port'],
        ['garbdConfig', 'cmdline'],
        getTopologyValidator(form, ['garbdConfig', 'address']),
    ];
}
