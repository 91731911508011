const dashboard = {
    title: 'MySQL Replication - Replica',
    meta: {
        slug: 'mysql-replication-slave',
        supportClusterTypes: ['replication'],
    },
    panels: [
        {
            title: 'Replication Status',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 24,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveStatus': replicationSlave.status}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveStatus }}',
                },
            ],
        },
        {
            title: 'IO Running',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
                valueMaps: [
                    {
                        op: '=',
                        text: 'No',
                        color: '#DE3C4B',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveIoRunning': replicationSlave.slaveIoRunning}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveIoRunning }}',
                },
            ],
        },
        {
            title: 'SQL Running',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
                valueMaps: [
                    {
                        op: '=',
                        text: 'Yes',
                        value: '1',
                    },
                    {
                        op: '=',
                        text: 'No',
                        value: '0',
                        color: '#DE3C4B',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                // {
                //     expr:
                //         'mysql_slave_status_slave_sql_running{instance=~"$instance"}',
                // },

                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveSqlRunning': replicationSlave.slaveSqlRunning}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveSqlRunning }}',
                },
            ],
        },
        {
            title: 'Replication Lag (sec)',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'long',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
                valueMaps: [
                    {
                        op: '<',
                        value: 10,
                    },
                    {
                        op: '>=',
                        value: 10,
                        color: '#DE3C4B',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                // {
                //     expr:
                //         'mysql_slave_status_seconds_behind_master{instance=~"$instance"}',
                // },
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveSecondsBehindMaster': replicationSlave.secondsBehindMaster}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveSecondsBehindMaster }}',
                },
            ],
        },
        {
            title: 'Last IO ErrNo',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
                valueMaps: [
                    {
                        op: '=',
                        value: '0',
                    },
                    {
                        op: '>',
                        value: '0',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                // {
                //     expr:
                //         'mysql_slave_status_last_io_errno{instance=~"$instance"}',
                // },
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveLastIoError': replicationSlave.lastIoError}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveLastIoError }}',
                },
            ],
        },
        {
            title: 'Last SQL ErrNo',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 0,
                format: 'short',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
                valueMaps: [
                    {
                        op: '=',
                        value: '0',
                    },
                    {
                        op: '>',
                        value: '0',
                    },
                ],
            },
            datasource: 'clustercontrol',
            targets: [
                // {
                //     expr:
                //         'mysql_slave_status_last_sql_errno{instance=~"$instance"}',
                // },
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveLastSqlError': replicationSlave.lastSqlError}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveLastSqlError }}',
                },
            ],
        },
        {
            title: 'IO Thread Status',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveSlaveIoState': replicationSlave.slaveIoState}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveSlaveIoState }}',
                },
            ],
        },
        {
            title: 'SQL Thread Status',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 12,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveSlaveSqlState': replicationSlave.slaveSqlState}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveSlaveSqlState }}',
                },
            ],
        },
        {
            title: 'Primary Server Id',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                decimals: 2,
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                // {
                //     expr:
                //         'mysql_slave_status_master_server_id{instance=~"$instance"}',
                // },
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveMasterServerId': replicationSlave.masterServerId}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveMasterServerId }}',
                },
            ],
        },
        {
            title: 'Primary Host',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveMasterHost': replicationSlave.masterHost}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveMasterHost }}',
                },
            ],
        },
        {
            title: 'Primary Log File',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 8,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveMasterLogFile': replicationSlave.masterLogFile}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveMasterLogFile }}',
                },
            ],
        },
        {
            title: 'Primary Log Pos',
            type: 'singlestat',
            gridPos: {
                h: 4,
                w: 4,
                x: 0,
                y: 0,
            },
            options: {
                format: 'none',
                type: 'column',
                legend: {
                    alignAsTable: false,
                },
                yaxis: [],
                stack: false,
            },
            datasource: 'clustercontrol',
            targets: [
                {
                    expr:
                        "node:$[hostname='$instance' and nodetype='mysql' and role='slave'].{'replicationSlaveExecMasterLogPos': replicationSlave.execMasterLogPos}",
                    legendAsValue: true,
                    legendFormat: '{{ replicationSlaveExecMasterLogPos }}',
                },
            ],
        },
    ],
    templating: {
        list: [
            {
                datasource: 'clustercontrol',
                multi: false,
                label: 'Host',
                name: 'instance',
                query:
                    "host:$[nodes[(nodetype='mysql' or nodetype='galera') and role='slave']]",
                optionValue: 'hostname',
                optionText: 'hostname',
                sort: 1,
                isInstancePicker: true,
            },
        ],
    },
    schemaVersion: 1,
};
export default dashboard;
