import React, { useCallback, useMemo } from 'react';
import { Col, Row, Space, InputNumber } from 'antd';
import { Form } from 'antd';
import CcCluster from '../../../../../services/models/CcCluster';
import { getNodeHostWithDesc } from '../../../../Nodes/NodeFormat';
import { FormInstance } from 'antd/lib/form';
import SshCheckableNodesAutocomplete from '../../../../../common/DataEntry/SshCheckeableNodesAutocomplete';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTopologyValidator } from '../../../loadBalancerHelpers';

type ImportHAProxyConfigurationProps = {
    selectedCluster?: CcCluster;
    form: FormInstance;
};

export const haProxyConfigurationInitialValues = {
    haProxyConfiguration: {
        port: 9600,
    },
};

export default ImportHAProxyConfiguration;

function ImportHAProxyConfiguration({
    selectedCluster,
    form,
}: ImportHAProxyConfigurationProps) {
    const getOptions = useCallback((): DefaultOptionType[] | undefined => {
        return selectedCluster?.getDatabaseNodes().map((node) => {
            return {
                value: `${node.hostname}`,
                label: getNodeHostWithDesc(node),
            };
        });
    }, [selectedCluster]);

    const options: DefaultOptionType[] | undefined = useMemo(
        () => getOptions(),
        [selectedCluster]
    );

    return (
        <div style={{ minHeight: 450 }}>
            <Row>
                <Col span={24}>
                    <h3>Configuration</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item
                        name={['haProxyConfiguration', 'address']}
                        label={<Space>Server address</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select server address.',
                            },
                        ]}
                    >
                        <SshCheckableNodesAutocomplete
                            clusterId={selectedCluster?.clusterId}
                            emptyState={null}
                            singleNode={true}
                            formProps={{
                                primaryTitle: null,
                                primaryFormItemProps: {
                                    withLessMarginBottom: true,
                                },
                            }}
                            autocompleteOptions={options}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['haProxyConfiguration', 'port']}
                        label={<Space>Port</Space>}
                        rules={[
                            {
                                required: true,
                                message: 'Enter port number.',
                            },
                        ]}
                    >
                        <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                            placeholder="Enter port number."
                        ></InputNumber>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}

export function getImportHaProxyConfigurationValidation(form: FormInstance) {
    return [
        // @todo make validation reusable with "Create" forms
        ['haProxyConfiguration', 'address'],
        ['haProxyConfiguration', 'port'],
        getTopologyValidator(form, ['haProxyConfiguration', 'address']),
    ];
}
