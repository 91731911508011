import useFetch from '../../../common/useFetch';
import CmonClustersService from '../../../services/cmon/CmonClustersService';
import CmonConfigService from '../../../services/cmon/CmonConfigService';
import CcCluster from '../../../services/models/CcCluster';
import { useMemo } from 'react';
import CcPkgInfo from '../../../services/models/CcPkgInfo';
import CcNode, {
    CcNodeType,
    getLoadBalancerNodeTypes,
} from '../../../services/models/CcNode';

const UPGRADABLE_DB_NODE_TYPES = [
    CcNodeType.MYSQL,
    CcNodeType.GALERA,
    CcNodeType.POSTGRESQL,
    CcNodeType.MONGO,
    CcNodeType.TIMESCALEDB,
];
const UPGRADABLE_LOAD_BALANCER_NODE_TYPES = [
    ...getLoadBalancerNodeTypes(),
    CcNodeType.MEMCACHED,
];
export const CLUSTER_UPGRADABLE_NODE_TYPES = [
    ...UPGRADABLE_DB_NODE_TYPES,
    ...UPGRADABLE_LOAD_BALANCER_NODE_TYPES,
];

export type CLusterAvailableUpgradeRecord = {
    type: 'load_balancer' | 'db_node';
    name: string;
    packages?: CcPkgInfo[];
    upgradablePackages?: CcPkgInfo[];
    node?: CcNode;
    children?: number[];
};

type UseClusterAvailableUpgradesProps = {
    cluster: CcCluster;
    isMongo?: boolean;
};
export default function useClusterAvailableUpgrades({
    cluster,
    isMongo = false,
}: UseClusterAvailableUpgradesProps) {
    const { loading, refresh, data } = useFetch<any[]>({
        name: 'cluster-available-upgrades',
        useCache: false,
        autoFetch: true,
        fetchFn: async (params, opts) => {
            const { packages } = await CmonClustersService.availableUpgrades(
                {
                    cluster_id: cluster.clusterId,
                },
                opts
            );

            return packages;
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });

    /**
     * collecting packages for each nodes
     * e.g.
     * {
     *     '127.0.0.1:3306': [...]
     * }
     */
    const availablePackages = useMemo(() => {
        if (data) {
            const hostMap = cluster.getHosts().reduce((map: any, host) => {
                map[host.hostname] = host;
                return map;
            }, {});
            return data.reduce((a: any, pkg: CcPkgInfo) => {
                const host =
                    (pkg.hostName && hostMap[pkg.hostName]) || undefined;
                if (host) {
                    host.nodes.forEach((node: CcNode) => {
                        if (node.isType(getPkgNodeTypes(pkg))) {
                            const key = node.getHostWithPort();
                            if (!a[key]) {
                                a[key] = [];
                            }
                            a[key].push(pkg);
                        }
                    });
                }
                return a;
            }, {});
        }
        return [];
    }, [data, cluster]);

    const dataSource: CLusterAvailableUpgradeRecord[] = useMemo(() => {
        const data: CLusterAvailableUpgradeRecord[] = [];
        const [lb, db] = cluster.nodes.reduce(
            ([lb, db]: CcNode[][], node: CcNode) => {
                if (node.isType(UPGRADABLE_LOAD_BALANCER_NODE_TYPES)) {
                    lb.push(node);
                } else if (node.isType(UPGRADABLE_DB_NODE_TYPES)) {
                    db.push(node);
                }
                return [lb, db];
            },
            [[], []]
        );

        const createRow = (props: CLusterAvailableUpgradeRecord) => (
            node: CcNode
        ): CLusterAvailableUpgradeRecord => {
            const packages = availablePackages[node.getHostWithPort()];
            return {
                ...props,
                children: undefined,
                node,
                packages,
                upgradablePackages: packages?.filter(
                    (pkg: CcPkgInfo) =>
                        pkg.installedVersion !== pkg.availableVersion
                ),
            };
        };
        const getChildrenIndexes = (arr: any[], startIndex: number) => {
            return Array.from(
                { length: arr.length },
                (_, i) => i + startIndex + 1
            );
        };

        if (lb.length > 0 && !isMongo) {
            const header: CLusterAvailableUpgradeRecord = {
                name: 'Load balancer',
                type: 'load_balancer',
                // index of children nodes
                children: getChildrenIndexes(lb, data.length),
            };
            data.push(header);
            data.push(...lb.map(createRow(header)));
        }
        if (db.length > 0) {
            const header: CLusterAvailableUpgradeRecord = {
                name: 'Database nodes',
                type: 'db_node',
                children: getChildrenIndexes(db, data.length),
            };
            data.push(header);
            data.push(...db.map(createRow(header)));
        }
        return data;
    }, [cluster, availablePackages]);

    return {
        dataSource,
        loading,
        refresh,
    };
}

function getPkgNodeTypes(pkg: CcPkgInfo) {
    switch (pkg.hostClassName) {
        case 'CmonPostgreSqlHost':
            return [CcNodeType.POSTGRESQL];
        case 'CmonMySqlHost':
            return [
                CcNodeType.MYSQL,
                CcNodeType.GALERA,
                CcNodeType.NDB,
                CcNodeType.NDB_MGMD,
            ];
        case 'CmonProxySqlHost':
            return [CcNodeType.PROXYSQL];
        case 'CmonMaxScale':
            return [CcNodeType.MAXSCALE];
        case 'CmonGarbdHost':
            return [CcNodeType.GARBD];
        case 'CmonHaProxyHost':
            return [CcNodeType.HAPROXY];
        case 'CmonMongoHost':
            return [CcNodeType.MONGO];
        default:
            return [];
    }
}
