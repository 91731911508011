import React from 'react';
import { FormInstance } from 'antd/lib/form';
import SpaceDescriptions from '../../../../../common/Layout/SpaceDescriptions';
import { TopologyItem } from '../../../../Topology/TopologyItem';
import AppDivider from '../../../../../common/AppDivider';

export type CreatePgBouncerPreviewProps = {
    form: FormInstance;
};

export default CreatePgBouncerPreview;

function CreatePgBouncerPreview({ form }: CreatePgBouncerPreviewProps) {
    const { pgBouncerAuth, pgBouncerAddNodes } = form.getFieldsValue(true);
    return (
        <div>
            {pgBouncerAuth?.adminUser && (
                <SpaceDescriptions
                    direction="vertical"
                    title="Authentication"
                    size="small"
                    alignItems="right"
                >
                    <SpaceDescriptions.Item
                        label="PgBouncer admin user"
                        labelStrong
                    >
                        {`${pgBouncerAuth?.adminUser}`}
                    </SpaceDescriptions.Item>
                </SpaceDescriptions>
            )}
            <AppDivider />
            <SpaceDescriptions
                direction="vertical"
                title="Add nodes"
                size="small"
                alignItems="right"
            >
                {Array.isArray(pgBouncerAddNodes.hostname) &&
                    pgBouncerAddNodes.hostname.map(
                        (topology: TopologyItem, index: number) => {
                            return (
                                <SpaceDescriptions.Item
                                    key={index}
                                    labelMuted
                                    label={`PgBouncer node  ${index + 1}`}
                                >
                                    {topology?.extraData.hostname ?? ''}
                                </SpaceDescriptions.Item>
                            );
                        }
                    )}
                <SpaceDescriptions.Item label="Listen port" labelStrong>
                    {`${pgBouncerAddNodes.listenPort ?? ''}`}
                </SpaceDescriptions.Item>
            </SpaceDescriptions>
            <AppDivider />
        </div>
    );
}
