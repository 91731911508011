import React from 'react';
import { Col, Row, Form, Input } from 'antd';

export default ImportPgBouncerAuthentication;

function ImportPgBouncerAuthentication() {
    return (
        <div style={{ minHeight: 450 }}>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <h3>Authentication</h3>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={['pgBouncerAuth', 'adminUser']}
                        label="PgBouncer admin user"
                    >
                        <Input placeholder="Enter Admin User."></Input>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['pgBouncerAuth', 'adminPassword']}
                        label="PgBouncer admin password"
                    >
                        <Input.Password placeholder="Enter Admin Password." />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
