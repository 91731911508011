import { FormInstance } from 'antd/lib/form';

export const privilegeOptions = [
    { label: 'CREATE', value: 'CREATE' },
    { label: 'DROP', value: 'DROP' },
    { label: 'GRANT OPTION', value: 'GRANT OPTION' },
    { label: 'LOCK TABLES', value: 'LOCK TABLES' },
    { label: 'REFERENCES', value: 'REFERENCES' },
    { label: 'EVENT', value: 'EVENT' },
    { label: 'ALTER', value: 'ALTER' },
    { label: 'DELETE', value: 'DELETE' },
    { label: 'INDEX', value: 'INDEX' },
    { label: 'INSERT', value: 'INSERT' },
    { label: 'SELECT', value: 'SELECT' },
    { label: 'UPDATE', value: 'UPDATE' },
    { label: 'CREATE TEMPORARY TABLES', value: 'CREATE TEMPORARY TABLES' },
    { label: 'TRIGGER', value: 'TRIGGER' },
    { label: 'CREATE VIEW', value: 'CREATE VIEW' },
    { label: 'SHOW VIEW', value: 'SHOW VIEW' },
    { label: 'ALTER ROUTINE', value: 'ALTER ROUTINE' },
    { label: 'CREATE ROUTINE', value: 'CREATE ROUTINE' },
    { label: 'EXECUTE', value: 'EXECUTE' },
    { label: 'FILE', value: 'FILE' },
    { label: 'CREATE TABLESPACE', value: 'CREATE TABLESPACE' },
    { label: 'CREATE USER', value: 'CREATE USER' },
    { label: 'PROCESS', value: 'PROCESS' },
    { label: 'PROXY', value: 'PROXY' },
    { label: 'RELOAD', value: 'RELOAD' },
    { label: 'REPLICATION CLIENT', value: 'REPLICATION CLIENT' },
    { label: 'REPLICATION SLAVE', value: 'REPLICATION SLAVE' },
    { label: 'SHOW DATABASES', value: 'SHOW DATABASES' },
    { label: 'SHUTDOWN', value: 'SHUTDOWN' },
    { label: 'SUPER', value: 'SUPER' },
    { label: 'ALL', value: 'ALL' },
    { label: 'ALL PRIVILEGES', value: 'ALL PRIVILEGES' },
    { label: 'USAGE', value: 'USAGE' },
];

export function serverInstanceNodeAddress(formObject: any) {
    let finalArray: object[] = [];
    Object.keys(formObject).forEach(function (key) {
        if (key.indexOf('node') === 0 && formObject[key]) {
            finalArray.push({
                hostname: formObject[`hostname${key.split('node')[1]}`],
                max_replication_lag: +formObject[
                    `maxReplicationLag${key.split('node')[1]}`
                ],
                max_connection: +formObject[
                    `maxConnection${key.split('node')[1]}`
                ],
                weight: +formObject[`weight${key.split('node')[1]}`],
                port: +formObject[`port${key.split('node')[1]}`],
            });
        }
    });
    finalArray.filter((hasValue) => hasValue !== undefined);

    return finalArray;
}

export function formatFields(form: FormInstance) {
    const {
        proxySQLWhere,
        proxySQLConfiguration,
        proxySQLServerInstances,
    } = form.getFieldsValue(true);
    let nodeAddress = serverInstanceNodeAddress(proxySQLServerInstances);

    const {
        version,
        adminPort,
        listenPort,
        firewall,
        appArmor,
        importConfig,
        nativeClustering,
    } = proxySQLWhere;
    const [address] = proxySQLWhere.address;

    const {
        administrationUser,
        administrationPassword,
        monitorUser,
        monitorPassword,
        dbUsername,
        dbPassword,
        dbName,
        sqlPrivileges,
    } = proxySQLConfiguration;

    if (proxySQLWhere?.proxySQLInstance !== undefined && importConfig) {
        const [proxySQLInstance] = proxySQLWhere.proxySQLInstance;
        const date = {
            action: 'setupProxySql',
            node: {
                hostname: address?.extraData?.hostname,
                port: adminPort,
            },
            listening_port: listenPort,
            sync_with_instance: proxySQLInstance?.extraData?.hostname,
            disable_firewall: firewall,
            disable_selinux: appArmor,
        };
        return date;
    } else {
        const date = {
            action: 'setupProxySql',
            node: {
                hostname: address?.extraData.hostname,
                port: adminPort,
            },
            listening_port: listenPort,
            admin_user: administrationUser,
            admin_password: administrationPassword,
            monitor_user: monitorUser,
            monitor_password: monitorPassword,
            db_username: dbUsername ? dbUsername.split('@')[0] : null,
            db_password: dbPassword ? dbPassword : null,
            db_privs: Array.isArray(sqlPrivileges)
                ? sqlPrivileges.join(',')
                : '',
            db_database: dbName ? dbName : '*.*',
            node_addresses: nodeAddress,
            use_rw_split: !importConfig,
            version: +version,
            disable_firewall: firewall,
            disable_selinux: appArmor,
            use_clustering: nativeClustering,
        };
        return date;
    }
}
